// Kazakh
export default{
    form: {
        startSurveyButton: "Бастайық",
        nextButton: "Келесі",
        submitAnswerButton: "Жауапты жіберу",
        continueButton: "Жалғастыру",
        finishButton: "Аяқтау",
        headerPercentageCompletion: 'аяқталды',
        headerQuestionsCompleted: 'сұрақтар',
        headerDefaultErrorMessage: 'Ой, бірдеңе дұрыс болмады.',
        brandingPoweredBy: 'Ұсынған',
        brandingPromoWhySurvey: 'Сауалнама өткізгенше <b>{product}</b> пайдаланбаған жөн бе?',
        brandingPromoGetStarted: 'Бүгін бастаңыз!',
        submissionPending: "Сауалнамаңыз жіберілуде. Браузер терезесін жаппаңыз.",
        submissionCanCloseWindow: "Сауалнамаңыз сәтті жіберілді. Енді терезені жабуға болады 👍",
        mediaAttachmentOverlayCloseButton: "Жабу",
        expiredFormHeader: "Келгеніңіз үшін рақмет!",
        expiredFormMessage: "Бұл сауалнаманың мерзімі аяқталған сияқты. Сауалнаманы жасаушыға хабарласыңыз.",
        notPublishedFormHeader: "Сауалнама жарияланбаған!",
        notPublishedFormMessage: "Бұл сауалнама әлі жарияланбаған сияқты. Сауалнаманы жасаушыға хабарласыңыз.",
        notPublishedFormRetryButton: "Қайтадан көру",
        errorFormHeader: "Қап!",
        errorFormMessage: "Бірдеңе дұрыс болмауы мүмкін. Кейінірек қайта көріңіз.",
        errorFormRetryButton: "Қайтадан көру",
        emptyFormHeader: "Келгеніңіз үшін рақмет!",
        emptyFormMessage: "Қап! Бұл формада қазір сұрақтар немесе толтыруға арналған өрістер жоқ сияқты. Ыңғайсыздық үшін кешірім сұраймыз. Бұл мәселені шешу үшін форма жасаушымен хабарласыңыз. Түсіністікпен қарағаныңыз үшін рақмет!",

        restoreFormHeader: "Сізде аяқталмаған сауалнама бар сияқты!",
        restoreFormMessage: "Қай жерден тоқтағаныңызды жалғастырғыңыз келе ме?",
        restoreFormButton: "Сауалнаманы жалғастыру",
        restoreFormButtonNew: "Басынан бастау",

        audioPermissionHeader: "Дауыс арқылы жауап бергіңіз келе ме?",
        audioPermissionMessage: "Сөйлеу жауап беруді жылдам әрі оңай етеді.",
        audioPermissionAcceptButton: "Иә, мен қалаймын",
        audioPermissionDenyButton: "Жоқ, рақмет",
        audioPermissionErrorHeader: "Микрофон қол жетімді емес",
        audioPermissionErrorMessage: "Микрофонға кіруді қосу үшін браузер рұқсаттарын тексеріңіз.",
        audioPermissionErrorButton: "Жарайды",
        audioRecorderFailedUpload: "Жазбаны жүктеу сәтсіз аяқталды",
        silenceDetectorError: "Сізді ести алмаймыз. Микрофон параметрлерін тексеріңіз. Кеңес: Сыртқы микрофонды қолдануыңыз мүмкін.",
        audioRecorderButtonRecord: "Жазуды бастаңыз",
        audioRecorderButtonRecordMore: "Көбірек жазу",
        voiceResponseSelectionMessage: "Қалай жауап бергіңіз келетінін таңдаңыз...",
        voiceResponseSelectionOrDivider: "немесе",
        voiceResponseTextInputPlaceholder: "Жауапты теріңіз",
        voiceResponseTextInputCharacterCounterMinMessage: "Кемінде {min} таңба енгізіңіз",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} таңба (кемінде {min} таңба)",
        voiceResponseTextInputCharacterValidationMessage: "{count} таңба (кемінде {min} таңба)",

        // checkbox
        checkboxValidationTooFew: "Кемінде {min} опцияны таңдау керек",
        checkboxValidationTooMany: "Ең көбі {max} опцияны таңдауға болады",
        checkboxNoneOfTheAboveOption: "Жоғарыдағылардың ешқайсысы",
        checkboxInvalidAnswer: "Қате жауап.",

        // datepicker
        datePickerPlaceholder: "Күнді таңдаңыз",

        // dropdown
        // email
        emailLabel: "Электрондық пошта",
        emailInvalid:"Қате электрондық пошта.",
        // file-upload
        fileUploadPluginNameCamera: "Камера",
        fileUploadPluginNameCameraVideo: "Бейне жазу",
        fileUploadPluginScreenCast: "Экран жазу",
        fileUploadVideoImportFilesDefault: 'Бейнені жазу немесе жүктеу:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Бейне жазу параметрін таңдаңыз:',
        fileUploadVideoImportFilesNoCamera: 'Бейнені экранға жазу үшін батырманы басыңыз',
        fileUploadVideoImportFilesNoScreenCast: 'Бейнені жазу үшін батырманы басыңыз',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Бейнені жазуға арналған параметрлер жоқ',

        // matrix
        matrixValidationMessage: "Жауап жарамды.",
        matrixRow: "Қатар",
        matrixColumn: "Баған",
        matrixRowRequired: "{rowTitle} қатары міндетті.",
        matrixRadioGroup: "Радио тобы",
        matrixCheckboxGroup: "Құсбелгі тобы",
        matrixGroupRequired: "{rowTitle} қатары үшін. {type} {groupTitle} қажет.",
        matrixColumnRequired: "{rowTitle} қатары үшін. Баған {columnTitle} қажет.",
        matrixColumnInvalid: "{rowTitle} қатары үшін. Баған {columnTitle} жарамсыз.",
        matrixRequired: "Міндетті.",
        matrixNumericMustBeNumber: "Сан болу керек.",
        matrixNumericMustBeGreaterThenMin: "{min} санынан үлкен болу керек.",
        matrixNumericMustBeLessThenMax: "{max} санынан кіші болу керек.",
        matrixNumericMustBeInteger: "Бүтін сан болу керек.",
        matrixNumericInvalidNumber: "Қате сан.",

        // name
        nameLabel: "Аты",
        nameInvalid: "Есім мен тегін көрсетіңіз.",

        // nps
        npsNotLikely: "Мүлдем ықтимал емес",
        npsExtremelyLikely: "Өте ықтимал",

        // numeric input
        numericInputRequired: "Міндетті.",
        numericInputMustBeNumber: "Сан болу керек.",
        numericInputMustBeGreaterThenMin: "{min} санынан үлкен болу керек.",
        numericInputMustBeLessThenMax: "{max} санынан кіші болу керек.",
        numericInputMustBeInteger: "Бүтін сан болу керек.",
        numericInputInvalidNumber: "Қате сан.",
        numericInputPlaceholder: "Санды енгізіңіз",

        // phone
        phoneInvalid: "Телефон нөмірі жарамсыз.",
        phoneCountrySelectorLabel: 'Ел коды',
        phoneCountrySelectorError: 'Елді таңдаңыз',
        phoneNumberLabel: 'Телефон нөмірі',
        phoneExample: 'Мысал:',

        // boolean
        booleanYesLabel: "Иә",
        booleanNoLabel: "Жоқ",

        //questionStep
        questionStepAudioQuestionLabel: "Дыбыстық сұрақ",

        // errors
        invalidPhoneNumber: "{phone} қате телефон нөмірі.",
        invalidEmail: "{email} қате электрондық пошта мекенжайы.",
        questionIsRequired: "Сұрақ міндетті.",
        answerIsNotValid: "Жауап жарамсыз.",
        unfinishedProbingDialogError: "Әңгімелесуді аяқтаңыз.",
        cannotResumePartialResponse: "Жауапты қайта жалғастыру мүмкін емес.",
        failedToSubmitForm: "Форманы жіберу сәтсіз аяқталды. Интернет қосылымын тексеріп, қайталап көріңіз.",

        //language picker
        searchLanguage: "Тілді іздеу",
    }
}
